import { Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";
import { PatternValue } from "core";
import React from "react";
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import Gameplan from "./Gameplan";

interface Props {
  pattern: PatternValue;
}

export default function GameplanCard({ pattern }: Props) {
  const textColor = useColorModeValue("gray.700", "white");
  const gameplans = [
    { id: pattern.gameplanId, title: "Impulse moments" },
    { id: pattern.successGameplanId, title: "After a success" },
    { id: pattern.setbackGameplanId, title: "After a setback" },
  ];

  return (
    <Card p="16px" mb="24px">
      <CardHeader p="12px 5px" mb="12px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            {pattern.name}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody px="5px">
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }}
          templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
          gap="24px"
        >
          {gameplans.map(({ id, title }) =>
            id ? <Gameplan id={id} title={title} /> : null
          )}
        </Grid>
      </CardBody>
    </Card>
  );
}
