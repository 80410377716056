import {
  Box,
  Button,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocation } from "react-router-dom";
import {
  renderThumbDark,
  renderThumbLight,
  renderTrack,
  renderView,
} from "../../components/Scrollbar/Scrollbar";
import { HSeparator } from "../../components/Separator/Separator";
import { Routes } from "../../router";
import SidebarLink from "./SidebarLink";

interface Props {
  routes: Routes;
  logo: any;
}
export default function Sidebar({ logo, routes }: Props) {
  const mainPanel = React.useRef();
  const variantChange = "0.2s linear";
  const location = useLocation();
  const auth = getAuth();

  function handleSignOutButtonClick() {
    auth.signOut();
    window.location.href = "/";
  }

  const sidebarBg = useColorModeValue("white", "navy.800");
  const sidebarRadius = "20px";
  const sidebarMargins = "0px";
  const isActiveRoute = (path: string) => location.pathname === path;

  var brand = (
    <Box pt={"25px"} mb="12px">
      {logo}
      <HSeparator my="26px" />
    </Box>
  );

  return (
    <Box ref={mainPanel as any}>
      <Box display={{ sm: "none", lg: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          filter="drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))"
          borderRadius={sidebarRadius}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={useColorModeValue(
              renderThumbLight,
              renderThumbDark
            )}
            renderView={renderView}
          >
            <Box>{brand}</Box>
            <Stack direction="column" mb="40px">
              <Box>
                {routes.map((route: any, key: any) => (
                  <SidebarLink
                    route={route}
                    isActive={isActiveRoute(route)}
                    key={key}
                    variantChange={variantChange}
                  />
                ))}
              </Box>
              <Spacer flexGrow={1} />
              <Text
                textAlign="center"
                color="#666"
                fontSize={14}
                data-testid="current-user-email"
              >
                {`Hey, ${auth.currentUser?.email || ""}!`}
              </Text>

              <Button onClick={handleSignOutButtonClick}>Sign out</Button>
            </Stack>
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
}
