import { TacticValue } from "core";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { useStorage } from "reactfire";
import useChangeEffect from "./useChangeEffect";

const DEFAULT_URI =
  "https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8dmVjdG9yfHx8fHx8MTY4NDQzNTQ3OQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080";

export function useImage(
  image: TacticValue["image"],
  defaultUri: string | null = DEFAULT_URI
) {
  const storage = useStorage();
  const [imageUri, setImageUri] = useState(
    image?.uri || defaultUri ? defaultUri : null
  );

  useEffect(() => {
    if (image?.uri) setImageUri(image.uri);
  }, [image?.uri]);

  useEffect(() => {
    if (image?.storagePath) {
      getDownloadURL(ref(storage, image.storagePath)).then(setImageUri);
    }
  }, [image?.storagePath]);

  useChangeEffect(() => {
    if (!image?.storagePath && !image?.uri) setImageUri(defaultUri);
  }, [image?.uri, image?.storagePath]);

  return imageUri;
}
