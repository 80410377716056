import { Flex, Text } from "@chakra-ui/react";
import { PatternValue, ProfileValue } from "core";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import PatternCard from "./components/PatternCard";
import ProfileHeader from "./components/ProfileHeader";

export default function UserScreen() {
  const { id } = useParams();
  const firestore = useFirestore();
  const { data: header, status } = useFirestoreDocData(
    doc(firestore, "profiles", id!) as DocumentReference<ProfileValue>
  );
  const { data: patterns = [] } = useFirestoreCollectionData(
    query(
      collection(firestore, "patterns") as CollectionReference<PatternValue>,
      where("uid", "==", id!)
    )
  );

  if (status === "loading") return <Text>"..."</Text>;
  if (!header) return <Text>Not found</Text>;

  return (
    <Flex direction="column">
      <ProfileHeader profile={header} />

      {patterns.map((pattern) => (
        <PatternCard pattern={pattern} />
      ))}
    </Flex>
  );
}
