import { Box, Text } from "@chakra-ui/react";
import { TacticValue } from "core";
import { collection } from "firebase/firestore";
import React from "react";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { Snap } from "../../utils/firestore";
import TacticRow from "./TacticRow";

export default function Tactics() {
  const firestore = useFirestore();
  const { data: tactics = [] } = useFirestoreCollection(
    collection(firestore, "tactics")
  );

  return (
    <Box>
      <Text>Tactics</Text>
      {(tactics as Array<Snap<TacticValue>>).map((tactic) => (
        <TacticRow tactic={tactic}></TacticRow>
      ))}
    </Box>
  );
}
