import { Box, Heading, Stack } from "@chakra-ui/react";
import { ParsedToken } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth, useSigninCheck } from "reactfire";
import Footer from "../components/Footer/Footer";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import Sidebar from "../components/Sidebar/Sidebar";
import { ClaimsContext } from "../contexts/ClaimsContext";
import { routes } from "../router";
import { isSubsequentSession, setIsSubsequentSession } from "../utils/sessions";

export default function Dashboard() {
  const auth = useAuth();
  const [claims, setClaims] = useState<null | ParsedToken>(null);
  const { status } = useSigninCheck();

  useEffect(() => {
    if (!auth.currentUser) return;

    auth.currentUser
      .getIdTokenResult()
      .then((token) => setClaims(token.claims));

    setIsSubsequentSession();
  }, [auth.currentUser]);

  if (status !== "loading" && !auth.currentUser) {
    return (
      <Navigate to={isSubsequentSession() ? "/signin" : "/signup"} replace />
    );
  }

  if (status === "loading") return <div>Loading...</div>;

  if (claims === null) return <Box>Verifying access...</Box>;

  const allRoutes = [
    routes[0],
    ...routes[0].children!,
    routes[routes.length - 1],
  ];
  const sidebarRoutes = allRoutes.filter((route) => {
    if (!route.showInSidebar) return false;
    if (route.requiredClaims) {
      return route.requiredClaims.every((claim) => claims[claim]);
    }

    return true;
  });

  return (
    <Box minH="100vh">
      <Box
        minH="100vh"
        w="100%"
        position="absolute"
        bg={"primary.700"}
        bgSize="cover"
        top="0"
      />
      <Sidebar
        routes={sidebarRoutes}
        logo={
          <Stack direction="column">
            <Stack direction="row" align="center" justify="center">
              <Heading size={"md"}>Impulse Dashboard</Heading>
            </Stack>
          </Stack>
        }
      />
      <ClaimsContext.Provider value={{ claims }}>
        <MainPanel
          w={{
            base: "100%",
            lg: "calc(100% - 275px)",
          }}
          minH="100vh"
        >
          <PanelContent h="100vh">
            <PanelContainer h="100vh">
              <Outlet />
            </PanelContainer>
          </PanelContent>
          <Footer />
        </MainPanel>
      </ClaimsContext.Provider>
    </Box>
  );
}
