import { Avatar, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { ProfileValue } from "core";
import React from "react";
import { useImage } from "../../../../../utils/images";

interface Props {
  profile: ProfileValue;
}

export default function ProfileHeader({ profile }: Props) {
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const textColor = useColorModeValue("gray.700", "white");
  const avatarUri = useImage(profile.avatar);

  return (
    <Flex
      direction={{ sm: "column", md: "row" }}
      mb="24px"
      maxH="330px"
      justifyContent={{ sm: "center", md: "space-between" }}
      align="center"
      backdropFilter="blur(21px)"
      boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
      border="1.5px solid"
      borderColor={borderProfileColor}
      bg={bgProfile}
      p="24px"
      borderRadius="20px"
    >
      <Flex
        align="center"
        mb={{ sm: "10px", md: "0px" }}
        direction={{ sm: "column", md: "row" }}
        w={{ sm: "100%" }}
        textAlign={{ sm: "center", md: "start" }}
      >
        {avatarUri ? (
          <Avatar
            me={{ md: "22px" }}
            src={avatarUri}
            w="80px"
            h="80px"
            borderRadius="15px"
          />
        ) : null}
        <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
          <Text
            fontSize={{ sm: "lg", lg: "xl" }}
            color={textColor}
            fontWeight="bold"
            ms={{ sm: "8px", md: "0px" }}
            data-testid="user-display-name"
          >
            {profile.displayName}
          </Text>
          <Text
            fontSize={{ sm: "sm", md: "md" }}
            color={emailColor}
            fontWeight="semibold"
          >
            {profile.email}
          </Text>
        </Flex>
      </Flex>
      {/* <Flex
        direction={{ sm: "column", lg: "row" }}
        w={{ sm: "100%", md: "50%", lg: "auto" }}
      >
        <Button p="0px" bg="transparent" variant="no-effects">
          <Flex
            align="center"
            w={{ sm: "100%", lg: "135px" }}
            bg={colorMode === "dark" ? "navy.900" : "#fff"}
            borderRadius="8px"
            justifyContent="center"
            py="10px"
            boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.06)"
            cursor="pointer"
          >
            <Icon color={textColor} as={FaCube} me="6px" />
            <Text fontSize="xs" color={textColor} fontWeight="bold">
              OVERVIEW
            </Text>
          </Flex>
        </Button>
        <Button p="0px" bg="transparent" variant="no-effects">
          <Flex
            align="center"
            w={{ lg: "135px" }}
            borderRadius="15px"
            justifyContent="center"
            py="10px"
            mx={{ lg: "1rem" }}
            cursor="pointer"
          >
            <Icon color={textColor} as={IoDocumentsSharp} me="6px" />
            <Text fontSize="xs" color={textColor} fontWeight="bold">
              TEAMS
            </Text>
          </Flex>
        </Button>
        <Button p="0px" bg="transparent" variant="no-effects">
          <Flex
            align="center"
            w={{ lg: "135px" }}
            borderRadius="15px"
            justifyContent="center"
            py="10px"
            cursor="pointer"
          >
            <Icon color={textColor} as={FaPenFancy} me="6px" />
            <Text fontSize="xs" color={textColor} fontWeight="bold">
              PROJECTS
            </Text>
          </Flex>
        </Button>
      </Flex> */}
    </Flex>
  );
}
