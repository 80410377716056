import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { TacticValue } from "core";
import React from "react";
import { Snap } from "../../utils/firestore";

interface Props {
  tactic: Snap<TacticValue>;
  isLast?: boolean;
}

export const columnNames = [
  "Ask",
  "Name",
  "Score",
  "Human Score",
  "Explanation",
  "",
];

export default function TacticRow({ tactic, isLast }: Props) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex
          direction={"column"}
          align="center"
          py=".8rem"
          minWidth="100%"
          flexWrap="nowrap"
        >
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {tactic.id}
          </Text>
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {tactic.data().title}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {tactic.data().description}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        {/* <Button
          disabled={isActionDisabled}
          onClick={handleToggleSelectedButtonClick}
        >
          {tactic.isSelected ? "Unselect" : "Select"}
        </Button> */}
      </Td>
    </Tr>
  );
}
