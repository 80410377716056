import React from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import {
  ClockIcon,
  HomeIcon,
  PersonIcon,
  RocketIcon,
} from "./components/Icons/Icons";
import Dashboard from "./layouts/Admin";
import Reports from "./views/Dashboard/Reports";
import SignIn from "./views/Pages/SignIn";
import SignOut from "./views/Pages/SignOut";
import SignUp from "./views/Pages/SignUp";
import Users from "./views/Pages/Users";
import UserScreen from "./views/Pages/Users/User";
import Tactics from "./views/Tactics";

type Route = Omit<RouteObject, "children"> & {
  label?: string;
  icon?: any;
  requiredClaims?: Array<string>;
  showInSidebar?: boolean;
  children?: Array<Route>;
};
export type Routes = typeof routes;
export const routes: Array<Route> = [
  {
    path: "/",
    label: "Dashboard",
    element: <Dashboard />,
    icon: <HomeIcon />,
    showInSidebar: false,
    requiredClaims: ["admin"],
    children: [
      { path: "/", element: <Reports /> },
      {
        path: "/users",
        label: "Clients",
        showInSidebar: true,
        icon: <PersonIcon />,
        element: <Users />,
        children: [
          {
            path: "/users/:id",
            element: <UserScreen />,
            children: [],
          },
        ],
      },
      {
        path: "/tactics",
        label: "Tactics",
        showInSidebar: false,
        icon: <RocketIcon />,
        element: <Tactics />,
      },
    ],
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signout",
    label: "Sign Out",
    icon: <ClockIcon />,
    element: <SignOut />,
  },
];
const router = createBrowserRouter(routes as Array<RouteObject>);

export default router;
