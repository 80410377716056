import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import signInImage from "../../assets/img/sanfran.jpeg";
import { parseError } from "../../utils/auth";

export default function SignUp() {
  const bgForm = useColorModeValue("white", "navy.800");
  const auth = getAuth();
  const [user, setUser] = useState(null as any);
  const [authErrors, setAuthErrors] = useState<{
    email?: string;
    password?: string;
  }>({});

  useEffect(() => {
    auth.onAuthStateChanged((user) => setUser(user));
  }, []);

  if (user) return <Navigate to="/" />;

  const initialValues = { email: "", password: "" };

  function onSubmit({ email, password }: typeof initialValues) {
    createUserWithEmailAndPassword(auth, email, password).catch((error) =>
      setAuthErrors(parseError(error.code))
    );
  }

  function validate(values: typeof initialValues) {
    const errors = {} as any;
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  }

  return (
    <Flex position="relative">
      <Flex
        minH={{ sm: "100vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        pt={{ md: "0px" }}
      >
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mb="60px"
          mt={{ base: "50px", md: "20px" }}
        >
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}
          >
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={onSubmit}
            >
              {({ values, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <h2 data-testid="auth-heading">Sign up</h2>
                  <h6
                    style={{
                      fontSize: 12,
                      marginBottom: 20,
                      fontFamily: "courier",
                    }}
                  >
                    **{process.env.REACT_APP_ENV} environment
                  </h6>
                  <FormControl isInvalid={!!authErrors.email} mb="24px">
                    <FormLabel fontSize="sm" fontWeight="normal">
                      Email
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      type="email"
                      placeholder="Your email"
                      size="lg"
                      value={values.email}
                      onChange={(e) => handleChange("email")(e.target.value)}
                    />
                    <FormErrorMessage>{authErrors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!authErrors.password} mb="24px">
                    <FormLabel fontSize="sm" fontWeight="normal">
                      Password
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      type="password"
                      placeholder="Your password"
                      size="lg"
                      value={values.password}
                      onChange={(e) => handleChange("password")(e.target.value)}
                    />
                    <FormErrorMessage>{authErrors.password}</FormErrorMessage>
                  </FormControl>
                  <Button
                    fontSize="15px"
                    variant="dark"
                    fontWeight="bold"
                    w="100%"
                    h="45"
                    mb="24px"
                    type="submit"
                  >
                    SIGN UP
                  </Button>
                  <Flex
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text flexGrow={1}>Already have an account?</Text>

                    <Button variant="ghost">
                      <Link to="/signin">Sign in</Link>
                    </Button>
                  </Flex>
                </form>
              )}
            </Formik>
          </Flex>
        </Flex>
        <Box
          overflowX="hidden"
          h="100%"
          w="100%"
          left="0px"
          position="absolute"
          bgImage={signInImage}
        >
          <Box
            w="100%"
            h="100%"
            bgSize="cover"
            bg="gray.900"
            opacity="0.4"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}
