const firebaseConfig = {
  apiKey: "AIzaSyCVRcp8LoR83nVd-ur3kEQ6MdOYMBevHhk",
  authDomain: "impulse-5501d.firebaseapp.com",
  projectId: "impulse-5501d",
  storageBucket: "impulse-5501d.appspot.com",
  messagingSenderId: "295869461622",
  appId: "1:295869461622:web:e07fbce2c143b50d025b57",
  measurementId: "G-2NYH6CQ63C",
};

export default firebaseConfig;
