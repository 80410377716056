import { Center } from "@chakra-ui/react";
import { collection, query, where } from "firebase/firestore";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";
import TableCard from "../../../components/TableCard";
import UserRow, { columnNames } from "./UserRow";

// The index component returns the list of users, or an outlet to the child (user) component
export default function UsersIndex() {
  let location = useLocation();
  const isIndex = location.pathname === "/users";
  if (isIndex) return <Users />;
  return <Outlet />;
}

function Users() {
  const firestore = useFirestore();
  const { uid } = useAuth().currentUser!;
  const { data = [] } = useFirestoreCollectionData(
    query(
      collection(firestore, "profiles"),
      where("viewAccessGrantedTo", "array-contains", uid)
    ),
    { idField: "id" }
  );

  return (
    <div>
      <TableCard columnNames={columnNames} title="Clients">
        {data.map((profile, index) => (
          <UserRow profile={profile} isLast={index === data.length - 1} />
        ))}
      </TableCard>
      {data.length === 0 && (
        <Center minH="50vh" color="gray.400">
          You don't have any clients here yet
        </Center>
      )}
    </div>
  );
}
