import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  profile: any;
  isLast?: boolean;
}

export const columnNames = [
  "Email",
  "ID",
  "Name",
  "App version",
  "Created",
  "Actions",
];

export default function UserRow({ profile, isLast }: Props) {
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Link to={`/users/${profile.id}`}>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {profile.email}
            </Text>
          </Link>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Link to={`/users/${profile.id}`}>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {profile.id}
            </Text>
          </Link>
        </Flex>
      </Td>

      <Td
        minWidth={{ sm: "100px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Text
          fontSize="md"
          color={titleColor}
          fontWeight="bold"
          minWidth="100%"
        >
          {[profile.firstName, profile.lastName].join(" ")}
        </Text>
      </Td>

      <Td
        minWidth={{ sm: "100px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Text
          fontSize="md"
          color={titleColor}
          fontWeight="bold"
          minWidth="100%"
        >
          {profile.app?.version || profile.app?.runtimeVersion}
        </Text>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
        pl="0px"
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {profile.createdAt?.toDate()?.toDateString()}
        </Text>
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
        pl="0px"
      >
        <Button p="0px" bg="transparent" variant="no-effects">
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Edit
          </Text>
        </Button>
      </Td>
    </Tr>
  );
}
