import { Divider, Heading, Text } from "@chakra-ui/react";
import { GameplanValue, TaskTactic } from "core";
import {
  CollectionReference,
  DocumentReference,
  Timestamp,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import React, { useMemo, useState } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import Card from "../../../../../components/Card/Card";
import Tactic from "./Tactic";
import TacticForm from "./TacticForm";

interface Props {
  id: string;
  title: string;
}

export default function Gameplan({ id, title }: Props) {
  const firestore = useFirestore();
  const gameplanDoc = useMemo(
    () => doc(firestore, "gameplans", id) as DocumentReference<GameplanValue>,
    [id]
  );
  const [editingTacticId, setEditingTacticId] = useState<string | null>(null);
  const { data } = useFirestoreDocData(gameplanDoc);

  async function handleAddSuggestion(title: string) {
    const newTactic = await addDoc(
      collection(firestore, "tactics") as CollectionReference<TaskTactic>,
      {
        title,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        ordinal: 0,
      }
    );
    updateDoc(gameplanDoc, {
      suggestedTacticIds: arrayUnion(newTactic.id),
    });
  }

  function handleUpdateTactic(title: string) {
    if (!editingTacticId) return Promise.resolve();
    const tacticDoc = doc(firestore, "tactics", editingTacticId);
    return updateDoc(tacticDoc, { title });
  }

  function handleRemoveTacticSuggestion(id: string) {
    if (confirm("Remove this tactic from the user's suggestions?")) {
      updateDoc(gameplanDoc, {
        suggestedTacticIds: arrayRemove(id),
      });
    }
  }

  if (!data) return <div />;

  return (
    <Card data-testid="gameplan-card" p="16px" mb="24px">
      <Heading mb={4} size="sm">
        {title}
      </Heading>
      <Text mb={2} fontSize={13} fontStyle="italic">
        Gameplan
      </Text>
      {data.tacticIds.map((tacticId) =>
        data.tacticsById[tacticId] ? (
          <Tactic tactic={data.tacticsById[tacticId]} />
        ) : null
      )}
      {data.tacticIds.length === 0 ? (
        <Text fontSize={12} color="gray.300">
          (Nothing here yet)
        </Text>
      ) : null}
      <Divider my={4} />
      <Text mb={2} fontSize={13} fontStyle="italic">
        Suggestions
      </Text>
      {data.suggestedTacticIds.map((tacticId) =>
        data.tacticsById[tacticId] ? (
          <Tactic
            tactic={data.tacticsById[tacticId]}
            onEditButtonClick={() => setEditingTacticId(tacticId)}
            onRemoveButtonClick={() => handleRemoveTacticSuggestion(tacticId)}
          />
        ) : null
      )}
      <TacticForm
        key={editingTacticId}
        editingTacticId={editingTacticId}
        initialTitle={
          editingTacticId
            ? data.tacticsById?.[editingTacticId]?.title
            : undefined
        }
        onCancelEditing={() => setEditingTacticId(null)}
        onSubmit={editingTacticId ? handleUpdateTactic : handleAddSuggestion}
      />
    </Card>
  );
}
