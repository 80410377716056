import React from "react";

export default function Reports() {
  return null; // For now, disable reportes
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
      }}
      src={"dashboardUrl"}
    />
  );
}
